<template>
  <div class="pt-10">
    <div class="vx-col w-full">
      <vx-card>
        <div class="vx-breadcrumb md:block hidden">
          <ul class="flex flex-wrap items-center">
            <li class="inline-flex items-end">
              <router-link :to="{ name: 'director-learning-centre-list' }"
                >Manage</router-link
              >
              <span class="breadcrumb-separator mx-2">
                <span class="feather-icon select-none relative">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24px"
                    height="24px"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="feather feather-chevrons-right"
                  >
                    <polyline points="13 17 18 12 13 7" />
                  </svg>
                </span>
              </span>
            </li>
            <li class="inline-flex items-end">
              <router-link
                :to="{
                  name: 'director-learning-centre-view-list',
                  params: { type: 'children', id: learningCenterId }
                }"
                >Children
              </router-link>

              <span class="breadcrumb-separator mx-2">
                <span class="feather-icon select-none relative">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24px"
                    height="24px"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="feather feather-chevrons-right"
                  >
                    <polyline points="13 17 18 12 13 7" />
                  </svg>
                </span>
              </span>
            </li>

            <li class="inline-flex items-center">
              <router-link
                :to="{
                  name: 'director-children-view',
                  params: { id: this.dataId }
                }"
                >{{ fullName }}
              </router-link>
              <span class="breadcrumb-separator mx-2">
                <span class="feather-icon select-none relative">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24px"
                    height="24px"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="feather feather-chevrons-right"
                  >
                    <polyline points="13 17 18 12 13 7" />
                  </svg>
                </span>
              </span>
            </li>
            <li class="inline-flex items-center">
              <a href="javascript:void(0)" class>Edit</a>
            </li>
          </ul>
        </div>
        <!-- <vs-divider class="mb-0 md:block hidden"></vs-divider> -->
        <div class="mt-6 flex items-center justify-between">
          <h2>Update Child Detail</h2>
        </div>

        <div class="w-full md:w-1/2">
          <div class="vx-col w-full mt-5 vs-con-loading__container">
            <template v-if="logo">
              <!-- Image Container -->
              <div class="img-container w-64">
                <img :src="logo" alt="img" class="responsive" />
              </div>

              <!-- Image upload Buttons -->
              <div class="modify-img flex my-8">
                <input
                  type="file"
                  class="hidden"
                  ref="updateImgInput"
                  @change="updateCurrImg"
                  accept="image/*"
                />
                <vs-button
                  type="border"
                  class="mr-4 btn-blue-border"
                  @click="$refs.updateImgInput.click()"
                  >Update Photo
                </vs-button>
                <vs-button color="danger" @click="logo = null"
                  >Remove Photo</vs-button
                >
              </div>
            </template>

            <div class="upload-img mt-5" v-if="!logo">
              <input
                type="file"
                class="hidden"
                ref="uploadImgInput"
                @change="updateCurrImg"
                accept="image/*"
              />
              <vs-button
                type="border"
                class="mr-4"
                @click="$refs.uploadImgInput.click()"
                >Upload Photo
              </vs-button>
            </div>
          </div>
          <!-- Room -->
          <div class="vx-col w-full mt-5">
            <div class="select-wrapper">
              <div class="vs-component is-label-placeholder">
                <!-- <label class="vs-input--label">Room</label> -->
                <v-select
                  :options="roomOptions"
                  :clearable="false"
                  :dir="$vs.rtl ? 'rtl' : 'ltr'"
                  v-model="roomFilter"
                  class="mb-4 md:mb-0"
                  data-vv-as="Room"
                >
                  <template #header>
                    <label for class="label">Room</label>
                  </template>
                  <template #open-indicator="{ attributes }">
                    <span v-bind="attributes">
                      <vs-icon icon="arrow_drop_down"></vs-icon>
                    </span>
                  </template>
                </v-select>
              </div>
            </div>
          </div>

          <vs-input
            label-placeholder="Child's Full Name"
            v-model="fullName"
            class="mt-5 w-full"
            name="fullName"
            v-validate="'required'"
            data-vv-as="Child Name"
          />
          <span class="text-danger text-sm" v-show="errors.has('fullName')">{{
            errors.first("fullName")
          }}</span>

          <!-- Age Group -->
          <div class="vx-col w-full mt-5">
            <div class="select-wrapper">
              <div class="vs-component is-label-placeholder">
                <!-- <label class="vs-input--label">Age Group</label> -->
                <v-select
                  :options="ageOptions"
                  :clearable="false"
                  :dir="$vs.rtl ? 'rtl' : 'ltr'"
                  v-model="ageFilter"
                  class="mb-4 md:mb-0"
                  data-vv-as="Age Group"
                >
                  <template #header>
                    <label for class="label">Age Group</label>
                  </template>
                  <template #open-indicator="{ attributes }">
                    <span v-bind="attributes">
                      <vs-icon icon="arrow_drop_down"></vs-icon>
                    </span>
                  </template>
                </v-select>
              </div>
            </div>
          </div>
          <!--Status-->
          <div class="w-full mt-5">
            <label class="vs-input--label">Account Status</label>
            <vs-switch v-model="status" />
            {{ status ? "Active" : "Inactive" }}
          </div>
          <!-- Parent Group -->
          <div class="vx-col w-full mt-5" v-if="selectedParent.length">
            <ul>
              <li
                v-for="(part, index) in selectedParent"
                v-bind:key="index"
                class="mb-2"
              >
                {{ part.label }}
                <span v-if="part.isPrimary">( Primary Parent )</span>
                <span v-else>
                  <a
                    href="javascript:void(0);"
                    @click="setPrimaryParent(index)"
                    class="underline text-sm"
                    >Set as Primary Parent</a
                  >
                </span>
              </li>
            </ul>
          </div>
          <div class="vx-col w-full mt-5" v-show="isShowParent">
            <div class="select-wrapper">
              <div class="vs-component is-label-placeholder">
                <!-- <label class="vs-input--label">Parent</label> -->
                <v-select
                  ref="select"
                  :options="parentOptions"
                  :clearable="false"
                  :dir="$vs.rtl ? 'rtl' : 'ltr'"
                  v-model="parentFilter"
                  class="mb-4 md:mb-0 parent-select"
                  @input="setSelected"
                  data-vv-as="Parent"
                >
                  <template #header>
                    <label for class="label">Parent</label>
                  </template>
                  <template #open-indicator="{ attributes }">
                    <span v-bind="attributes">
                      <vs-icon icon="arrow_drop_down"></vs-icon>
                    </span>
                  </template>
                </v-select>
              </div>
            </div>
          </div>
          <vs-button
            type="border"
            class="mb-md-0 mb-2 mt-5 btn-blue-border"
            align="right"
            @click="addNewParent"
            >Add Additional Parent
          </vs-button>
        </div>

        <div
          class="flex flex-wrap items-center py-6 w-full md:w-1/2 justify-end"
        >
          <vs-button color="danger" @click="cancelBtn">Cancel</vs-button>
          <vs-button class="ml-8" @click="submitData" :disabled="!isFormValid"
            >Save</vs-button
          >
        </div>
      </vx-card>
    </div>
  </div>
</template>

<script>
import vSelect from "vue-select";
import { mapActions } from "vuex";
import _ from "lodash";

import { Validator } from "vee-validate";

const dictionary = {
  en: {
    attributes: {
      fullName: "full name"
    }
  }
};
Validator.localize(dictionary);

export default {
  data() {
    return {
      dataId: null,
      fullName: "",
      settings: {
        maxScrollbarLength: 60,
        wheelSpeed: 0.6
      },
      roomOptions: [],
      ageOptions: [],
      parentOptions: [],
      logo: null,
      image: null,
      new_upload: "no",
      learningCenterId: null,
      roomFilter: { label: "Select room", value: "" },
      ageFilter: { label: "Select age group", value: "" },
      parentFilter: [{ label: "Add new parent", value: "add" }],
      selectedParent: [],
      selectedParentIndex: 0,
      isShowParent: true,
      customError: "",
      oldRoom: null,
      status: false
    };
  },
  props: {
    data: {
      type: Object,
      default: () => {}
    },
    centerId: {
      type: String,
      default: ""
    }
  },
  watch: {
    roomFilter(data) {
      if (data.ageGroupId) {
        this.ageFilter = _.find(this.ageOptions, function(o) {
          return o.value === data.ageGroupId;
        });
      }
    }
  },
  computed: {
    isFormValid() {
      return (
        !this.errors.any() &&
        this.roomFilter.value != "" &&
        this.fullName &&
        this.ageFilter.value != "" &&
        this.parentFilter.value != ""
      );
    },
    director() {
      return this.$store.state.AppActiveUser;
    }
  },
  methods: {
    ...mapActions("center", [
      "getDirectorChildDetails",
      "updateDirectorChild",
      "addDirectorChild",
      "imageUpload",
      "getDirectorAllCountries",
      "getAgeGroup",
      "getDirectorRooms",
      "getDirectorParentList",
      "getParentByLearningCenterId","getLearningCenterRooms"
    ]),
    updateCurrImg(input) {
      if (input.target.files && input.target.files[0]) {
        this.image = input.target.files[0];
        this.new_upload = "yes";
        var reader = new FileReader();
        reader.onload = e => {
          this.logo = e.target.result;
        };
        reader.readAsDataURL(input.target.files[0]);
      }
    },
    async submitData() {
      await this.$validator.validateAll().then(result => {
        if (result) {
          let data = new FormData();
          data.append("id", this.dataId);
          data.append("fullName", this.fullName);
          data.append("room", this.roomFilter.value);
          data.append("oldRoom", this.oldRoom);
          data.append("parent", JSON.stringify(this.selectedParent));
          data.append("age", this.ageFilter.value);
          data.append("status", this.status);

          if ("undefined" != typeof this.$route.params.id) {
            data.append("learningCenter", this.learningCenterId);
          }

          data.append("logo", this.image);
          data.append("new_upload", this.new_upload);

          this.customError = "";
          if (this.dataId !== null) {
            this.$vs.loading();
            this.updateDirectorChild(data)
              .then(async res => {
                if ((await res.status) === 200) {
                  this.showMessage(
                    "Success",
                    "Child Updated successfully.",
                    "success"
                  );
                  // this.$emit("refreshData", true);
                }
                this.$vs.loading.close();
              })
              .catch(err => {
                this.$vs.loading.close();
                // console.error(err)
              });
            this.$router.push({
              name: "director-learning-centre-view",
              params: { id: this.learningCenterId }
            });
          } else {
            if (this.logo == null) {
              this.customError = "Image field is required.";
              return;
            }
            this.$vs.loading();
            this.addDirectorChild(data)
              .then(res => {
                if (res.status === 200) {
                  this.$vs.loading.close();
                  this.$emit("refreshData", true);
                  this.showMessage(
                    "Success",
                    "Child added successfully.",
                    "success"
                  );
                }
              })
              .catch(err => {
                this.$vs.loading.close();
                if (err.response.status === "422") {
                  this.showMessage(
                    "Failed",
                    err.response.data.message,
                    "danger"
                  );
                }
                this.showMessage("Failed", "Failed to add child", "danger");
              });
          }
          this.$router.push({
            name: "director-children-view",
            params: { id: this.dataId }
          });
        }
      });
    },

    showMessage(title, message, color) {
      this.$vs.notify({
        title: title,
        text: message,
        iconPack: "feather",
        icon: "icon-alert-circle",
        color: color
      });
    },

    setSelected(event) {
      let self = this;
      if ("" == event.value) {
        this.$router.push({
          name: "director-parent-add",
          params: { id: this.learningCenterId }
        });
      } else {
        if (0 == this.selectedParent.length) {
          this.selectedParent.push({
            label: this.parentFilter.label,
            parentId: this.parentFilter._id,
            isPrimary: true
          });
        } else {
          this.selectedParent.push({
            label: this.parentFilter.label,
            parentId: this.parentFilter._id,
            isPrimary: false
          });
        }

        this.selectedParent = this.selectedParent.map(function(elem, index) {
          if (index == self.selectedParentIndex) {
            elem.isPrimary = true;
          } else {
            elem.isPrimary = false;
          }
          return elem;
        });
        this.isShowParent = false;
      }
    },
    addNewParent() {
      this.isShowParent = this.isShowParent ? false : true;
      if (0 == this.selectedParent.length) {
        this.isShowParent = true;
      }
    },

    setPrimaryParent(index) {
      let self = this;
      this.selectedParentIndex = index;
      this.selectedParent = this.selectedParent.map(function(elem, i) {
        if (i == self.selectedParentIndex) {
          elem.isPrimary = true;
        } else {
          elem.isPrimary = false;
        }
        return elem;
      });
    },

    async getDetails(id) {
      this.$vs.loading();
      let self = this;
      await this.getDirectorChildDetails(id)
        .then(async res => {
          let result = await res.data.data;
          // console.log( result );
          self.dataId = result._id;
          if (result.roomDetails.length > 0) {
            self.oldRoom = result.roomDetails[0]._id;
            self.roomFilter = {
              value: result.roomDetails[0]._id,
              label: result.roomDetails[0].name
            };
          }
          self.fullName = result.fullName;
          self.status = result.accountStatus == "Active";
          self.learningCenterId = result.learningCenterId;
          self.logo = result.photo;
          self.image = result.photo;
          (self.ageFilter = {
            label: result.ageDetails[0].ageGroup,
            value: result.ageDetails[0]._id
          }),
            (self.selectedParent = _.map(result.parentDetails, function(ele) {
              return {
                value: ele._id,
                parentId: ele._id,
                label: ele.fullName,
                isPrimary: false
              };
            }));

          _.forEach(result.parents, function(ele) {
            if (ele.isPrimary) {
              let findme = _.findIndex(self.selectedParent, function(o) {
                return ele.parentId == o.value;
              });
              self.selectedParent[findme].isPrimary = true;
            }
          });
          self.parentFilter = self.selectedParent;

          self.$vs.loading.close();
        })
        .catch(err => {
          self.$vs.loading.close();
          //console.error(err)
        });
    },
    cancelBtn() {
      this.$router.push({
        name: "director-children-view",
        params: { id: this.dataId }
      });
    }
  },
  async created() {
    let id = this.$route.params.id;
    if ("undefined" != typeof id) {
      await this.getDetails(id);
    }

    this.getAgeGroup()
      .then(res => {
        this.ageOptions = res;
      })
      .catch(err => {});
    this.getParentByLearningCenterId(this.learningCenterId)
      .then(res => {
        this.parentOptions = res;
      })
      .catch(err => {});

    this.getLearningCenterRooms(this.learningCenterId)
      .then(res => {
        this.roomOptions = res.data.data;
        this.roomOptions = this.roomOptions.map(item => {
          return {
            value: item._id,
            label: item.name,
            ageGroupId: item.ageGroup ? item.ageGroup : ""
          };
        });
      })
      .catch(err => {});

    this.selectedParentIndex = this.selectedParent.filter(function(elem, i) {
      return elem.isPrimary == true ? i : 0;
    });
  },
  components: {
    vSelect
    // VuePerfectScrollbar,
  }
};
</script>

<style lang="scss" scoped>
.add-new-data-sidebar {
  ::v-deep .vs-sidebar--background {
    z-index: 52010;
  }

  ::v-deep .vs-sidebar {
    z-index: 52010;
    width: 400px;
    max-width: 90vw;

    .img-upload {
      margin-top: 2rem;

      .con-img-upload {
        padding: 0;
      }

      .con-input-upload {
        width: 100%;
        margin: 0;
      }
    }
  }
}

.scroll-area--data-list-add-new {
  // height: calc(var(--vh, 1vh) * 100 - 4.3rem);
  height: calc(var(--vh, 1vh) * 100 - 16px - 45px - 82px);
}
</style>
